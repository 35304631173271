
  
.up-lbl {
    transform: translateY(-30px);
}

.modal {
    background: rgba(0, 0, 0, .8);
    
}
.disabled-btn-pvt {
    pointer-events: none;
    color: grey !important;
    background-color: grey;
}

.disabled {
    pointer-events: none;
    color: grey !important;
}

.long {
    height: 200px !important;
}