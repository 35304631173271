@charset "UTF-8";
/*
    Created on : 7-ott-2017, 13.08.21
    Author     : Simone Rescio
*/
/*
 * Indirizzo di produzione degli assets, inserire indirizzo reale
 */
._cCdZZ {
  min-width: 300px; }
  ._cCdZZ a {
    cursor: pointer;
    color: #06c !important; }
  ._cCdZZ h1 {
    color: #17324d;
    font-size: 2rem;
    font-family: "Titillium Web", HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }

._3Kl4y {
  display: flex;
  flex-flow: row wrap; }
  ._3Kl4y ._LKckz {
    margin: 12px auto;
    display: flex;
    flex-direction: column;
    width: 40%;
    background: #f5f6f7;
    border-radius: 3px; }
    ._3Kl4y ._LKckz img {
      max-height: 30px;
      height: 100%;
      width: auto; }
    ._3Kl4y ._LKckz span {
      padding-top: 5px;
      font-family: "Titillium Web", HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
      font-size: 1.2em;
      font-weight: bold; }
    ._3Kl4y ._LKckz form {
      height: 100%;
      padding: 10px; }
      ._3Kl4y ._LKckz form:active {
        border: 3px solid #f7910d; }
      ._3Kl4y ._LKckz form ._3Tob- {
        border: 0;
        width: 100%;
        cursor: pointer;
        background: #f5f6f7;
        padding: 5px;
        height: 100%; }
        ._3Kl4y ._LKckz form ._3Tob-[disabled] {
          opacity: 0.2;
          cursor: not-allowed; }
    ._3Kl4y ._LKckz a {
      height: 100%;
      border: 3px solid white;
      padding: 10px;
      text-decoration: none; }
      ._3Kl4y ._LKckz a:active {
        border: 3px solid #f7910d; }
      ._3Kl4y ._LKckz a[disabled] {
        opacity: 0.2;
        cursor: not-allowed;
        pointer-events: none; }

._1lKvK {
  float: left;
  width: 50%; }

._2-fJt {
  float: right;
  margin-top: 5px; }

._2QnKd,
._1OUxg {
  font-family: "Titillium Web", HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }

._2y2KE {
  background: transparent !important;
  cursor: pointer;
  font-size: 1em; }

._cCdZZ {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0; }

._3fX0d {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  font-size: 15px;
  font-family: "Titillium Web", HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }
  ._3fX0d a {
    font-weight: bold; }

._1PpRy {
  height: 0; }

._14gd1 {
  top: 0;
  left: 0;
  margin-left: 0%;
  margin-top: 0%;
  margin-right: 0%;
  width: 100vw;
  height: 100vh;
  color: #000;
  position: fixed;
  z-index: 1;
  overflow: auto;
  background: white; }

._1JIME {
  background-color: #fff;
  color: #fff;
  display: block;
  overflow: hidden;
  position: static;
  min-width: 300px;
  padding: 40px; }
  ._1JIME ._1lKvK {
    float: left;
    width: 33.33333vw;
    font-size: 30px; }
  ._1JIME ._5CMZw {
    min-width: 32px; }
    ._1JIME ._5CMZw a,
    ._1JIME ._5CMZw button,
    ._1JIME ._5CMZw button a {
      float: left;
      width: auto;
      border: none;
      outline: none;
      display: block;
      cursor: pointer; }
    ._1JIME ._5CMZw button:hover,
    ._1JIME ._5CMZw button:focus,
    ._1JIME ._5CMZw a:hover,
    ._1JIME ._5CMZw a:focus {
      opacity: 0.5; }
    ._1JIME ._5CMZw img {
      max-height: 50px; }
  ._1JIME ._1lKvK img {
    max-height: 37px; }

._1MYq8 {
  position: relative;
  display: block;
  min-width: 300px;
  margin-bottom: 10px; }
  ._1MYq8 ._3CTDc {
    background: transparent;
    display: block;
    text-align: center; }

._1f8Pp {
  text-align: center;
  margin: auto;
  overflow: auto;
  height: 100%;
  margin-bottom: 60px;
  max-width: 500px; }

._2izcf {
  border: none;
  padding-right: 8px;
  vertical-align: middle;
  display: inline-block; }
  ._2izcf svg {
    fill: #fff; }

._3-XVX {
  width: 32px;
  opacity: 0;
  position: absolute; }

@media screen and (max-width: 767px) {
  ._3-XVX {
    display: none; } }

._2UEmQ {
  vertical-align: top; }

._3Otew {
  font-size: 14px;
  height: 40px;
  padding: 10px 20px; }
  ._3Otew > span img {
    width: 16px;
    height: 16px;
    border: 0; }

._1e5UZ {
  font-size: 16px;
  height: 48px;
  padding: 12px 24px; }
  ._1e5UZ > span img {
    width: 24px;
    height: 24px;
    border: 0; }

._2y16B {
  font-size: 18px;
  height: 56px;
  padding: 12px 28px; }
  ._2y16B > span img {
    width: 32px;
    height: 32px;
    border: 0; }

._2ry0A {
  width: 100%;
  max-width: 400px; }

._1OUxg {
  background-color: #fcfdff !important;
  padding: 12px 24px;
  color: #50565c !important;
  font-family: "Titillium Web", HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  box-shadow: inset 0 0 0 1px #e6e9f2;
  border-radius: 4px;
  border: 0 solid transparent;
  height: 55px;
  margin: auto;
  width: 90%;
  text-align: center;
  line-height: 1.5;
  vertical-align: middle;
  cursor: pointer; }
  ._1OUxg:active:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(92, 111, 130, 0.5); }
  ._1OUxg:active {
    color: #5c6f82; }
  ._1OUxg:hover {
    color: #50565c;
    box-shadow: inset 0 0 0 1px #c9cedc; }

._2rEB8 {
  float: left;
  width: 100vw;
  width: 24.99999%; }
  ._2rEB8._2QCzb {
    width: 49.99999%; }

#_vG92d {
  padding-top: 1.25em; }

#_2Mtb6 {
  width: 100%; }
  #_2Mtb6 button {
    border: none; }

._2w-jv {
  width: 49.99999%;
  height: 65px;
  color: #0073e6;
  font-weight: 700;
  background-color: #fff;
  display: block;
  float: left; }
  ._2w-jv span {
    position: relative;
    top: -4px; }

._vYqK3 {
  display: block !important;
  opacity: 0; }

._3VqKK {
  color: #fff;
  background-color: #0073e6; }
  ._3VqKK:hover, ._3VqKK:focus, ._3VqKK:active {
    background-color: #0059b3;
    color: #fff; }
  ._3VqKK:visited {
    background-color: #0073e6;
    color: #fff; }
  ._3VqKK svg {
    fill: #fff; }

._z74_- {
  color: #0073e6;
  background-color: #fff; }
  ._z74_-:hover, ._z74_-:focus, ._z74_-:active {
    background-color: #fff;
    color: #0059b3; }
  ._z74_-:visited {
    background-color: #fff;
    color: #0073e6; }

._2dbjY {
  border-radius: 4px;
  margin: 16px; }

._2LEdl {
  border-radius: 0; }

/*
 * Deve essere l'ultimo set di regole, così che dopo che i pulsanti sono stati renderizzati con invisibilità inline
 * si da tempo al browser di caricare e parsare i fogli di stile appena iniettati, di google font e del presente
 * così da evitare di vedere prima i pulsanti "rotti" che poi si aggiustano gradualmente mentre il CSS viene scaricato e parsato
 * in questo modo i pulsanti appaiono pochi istanti dopo correttamente stilizzati, sovrascrivendo l'attributo hidden
 */
._2QnKd {
  position: relative;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 600; }
  ._2QnKd ._1lKvK {
    position: absolute;
    display: block;
    margin: auto;
    width: 12%;
    top: 30%;
    left: 14%; }

._1RZht {
  text-align: center;
  max-width: 500px;
  bottom: 0;
  background-color: white;
  margin: -40px auto auto auto;
  width: 100%;
  padding-bottom: 4%;
  position: relative; }
  ._1RZht::after {
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
    left: 10%;
    right: 10%;
    width: 80%;
    height: 50%;
    border-radius: 100%; }

@media screen and (max-width: 767px) {
  ._1RZht {
    position: fixed;
    left: 50%;
    transform: translateX(-50%); } }

._3DmkR {
  animation-delay: 0s;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards; }

._cCdZZ {
  display: block;
  transition-duration: 1s;
  transition-delay: 0.5s;
  transition-property: visibility; }
  ._cCdZZ[hidden] {
    visibility: hidden;
    transition-duration: 0s;
    transition-property: visibility;
    transition-delay: 1s;
    pointer-events: none; }
    ._cCdZZ[hidden] ._3DmkR {
      animation-name: _1g5r_;
      animation-duration: 1s; }
      ._cCdZZ[hidden] ._3DmkR ._1AcG2 {
        animation-name: _U96wZ;
        animation-duration: 0.5s;
        animation-fill-mode: both; }
      ._cCdZZ[hidden] ._3DmkR ._4bJkX {
        animation-name: _rMjnb;
        animation-duration: 0.5s;
        animation-fill-mode: both; }
      ._cCdZZ[hidden] ._3DmkR ._30A8N {
        animation-name: _13pae;
        animation-duration: 0.5s;
        animation-fill-mode: both; }
  ._cCdZZ:not([hidden]) ._3DmkR {
    animation-name: _362tI;
    animation-duration: 2s;
    animation-delay: 0s; }
    ._cCdZZ:not([hidden]) ._3DmkR ._1oOqI {
      animation-name: _35Xw6;
      animation-duration: 0.5s;
      animation-delay: 0.5s;
      animation-fill-mode: both; }
    ._cCdZZ:not([hidden]) ._3DmkR ._28gE_ {
      animation-name: _2fvIS;
      animation-duration: 0.5s;
      animation-delay: 1s;
      animation-fill-mode: both; }
    ._cCdZZ:not([hidden]) ._3DmkR ._38Hat {
      animation-name: _19ue9;
      animation-duration: 0.5s;
      animation-delay: 0.5s;
      animation-fill-mode: both; }

._1Fbhp {
  animation-name: _1dYFC;
  animation-duration: 0.5s;
  animation-fill-mode: both; }

@keyframes _1dYFC {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

._3y9Gq {
  animation-name: _QIMMS;
  animation-duration: 0.5s;
  animation-fill-mode: both; }

@keyframes _QIMMS {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes _35Xw6 {
  from {
    transform: translate(-50px); }
  to {
    transform: none; } }

@keyframes _2fvIS {
  from {
    transform: none; }
  to {
    transform: translate(-50px); } }

@keyframes _19ue9 {
  from {
    opacity: 0;
    transform: translateY(200%); }
  to {
    opacity: 1;
    transform: none; } }

@keyframes _U96wZ {
  from {
    transform: none; }
  to {
    transform: translate(-50px); } }

@keyframes _rMjnb {
  from {
    transform: none; }
  to {
    transform: translate(-50px); } }

@keyframes _13pae {
  from {
    opacity: 0;
    transform: none; }
  to {
    opacity: 1;
    transform: translateY(200%); } }

@keyframes _3PUzY {
  0% {
    width: 180px;
    height: 48px;
    top: calc((100vh - 48px) / 2);
    left: calc((100vw - 180px) / 2);
    border-radius: 100%;
    opacity: 0; }
  50% {
    width: 180px;
    height: 48px;
    top: calc((100vh - 48px) / 2);
    left: calc((100vw - 180px) / 2);
    border-radius: 100%;
    opacity: 1; }
  100% {
    top: calc((100vh - 48px) / 2);
    left: calc((100vw - 180px) / 2);
    transform: scale(30);
    border-radius: 100%;
    opacity: 1; } }

@keyframes _18fzj {
  0% {
    top: calc((100vh - 48px) / 2);
    left: calc((100vw - 180px) / 2);
    transform: scale(30);
    border-radius: 100%;
    opacity: 1; }
  50% {
    width: 180px;
    height: 48px;
    top: calc((100vh - 48px) / 2);
    left: calc((100vw - 180px) / 2);
    border-radius: 100%;
    opacity: 0.5; }
  100% {
    width: 180px;
    height: 48px;
    top: calc((100vh - 48px) / 2);
    left: calc((100vw - 180px) / 2);
    border-radius: 100%;
    opacity: 0; } }

@keyframes _Gnu-k {
  0% {
    width: 30px;
    height: 30px;
    top: 50vh;
    left: 50vw;
    opacity: 0; }
  5% {
    width: 30px;
    height: 30px;
    top: 50vh;
    left: 50vw;
    opacity: 0.2; }
  100% {
    top: 0;
    left: 20vw;
    width: 120vw;
    height: 120vh;
    opacity: 0.1; } }

@keyframes _2Ellr {
  0% {
    top: 0;
    left: 20vw;
    width: 120vw;
    height: 120vh;
    opacity: 0.1; }
  50% {
    width: 30px;
    height: 30px;
    top: 50vh;
    left: 50vw;
    opacity: 0; }
  100% {
    width: 30px;
    height: 30px;
    top: 50vh;
    left: 50vw;
    opacity: 0; } }

@keyframes _362tI {
  0% {
    opacity: 0; }
  15% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes _1g5r_ {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes _2h0xR {
  0% {
    height: 100vh; }
  100% {
    height: 0; } }

._2QnKd._-zRgJ {
  animation-name: _2k2ra;
  animation-duration: 1s;
  animation-fill-mode: forwards; }

._2QnKd._2ptp7 {
  animation-name: _1fN5V;
  animation-duration: 1s; }

._3-XVX {
  animation-name: _26ZNK;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards; }

._2hUXa {
  animation-name: _19xJD;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards; }

._2hUXa._Tfm-p {
  animation-name: _2ExQV;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards; }

._Kb49i {
  animation-name: _j62fh;
  animation-duration: 1s;
  animation-timing-function: ease; }

@keyframes _26ZNK {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.15;
    position: absolute;
    top: 80%;
    left: 75%;
    width: 15%; } }

@media (max-width: 1110px) {
  @keyframes _26ZNK {
    100% {
      opacity: 0.15;
      position: absolute;
      top: 77%;
      left: 80%;
      width: 12%; } } }

@keyframes _j62fh {
  50% {
    opacity: 0; }
  100% {
    top: 0;
    left: 0; } }

@keyframes _2k2ra {
  from {
    outline: none;
    opacity: 1; }
  to {
    outline: none;
    opacity: 0.2; } }

@keyframes _2ExQV {
  0% {
    opacity: 1; }
  99% {
    opacity: 0.2; }
  100% {
    opacity: 0;
    transform-origin: top left;
    transform: translate(50vw, 50vh) scale(10); } }

@media screen and (max-width: 767px) {
  @keyframes _2ExQV {
    from {
      opacity: 1;
      visibility: hidden; }
    to {
      opacity: 0.2;
      transform: translate(50vw, 50vh) scale(10);
      visibility: visible; } } }

@keyframes _19xJD {
  0% {
    opacity: 0.2;
    transform-origin: top left;
    transform: translate(50vw, 50vh) scale(10); }
  100% {
    transform: none;
    opacity: 1; } }

@media screen and (max-width: 767px) {
  @keyframes _19xJD {
    from {
      opacity: 0.2;
      transform: translate(50vw, 50vh) scale(10); }
    to {
      transform: none;
      opacity: 1; } } }

@keyframes _1fN5V {
  from {
    opacity: 0.2; }
  to {
    opacity: 1; } }

img._2U-YE {
    vertical-align: baseline;
}
._3o5cV {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}
/**  
 * The content of this file is derived from: https://github.com/italia/spid-sp-access-button/blob/master/src/production/css/spid-sp-access-button.min.css
 * All original copyrights, credits and license apply here as: https://github.com/italia/spid-sp-access-button/blob/master/LICENSE.md
 */

@import url(https://fonts.googleapis.com/css?family=Titillium+Web:400,600);
:root {
    --text-color-positive: #FFF;
    --text-color-negative: #06C;
    --background-color-positive: #06C;
    --background-color-negative: #FFF;
    --color-darker: #036;
    --color-lighter: #83BEED;
}

._3fM8l {
    position: relative;
    display: inline-block;
}

._3KoyM {
    display: inline-block;
    position: relative;
    padding: 0;
    color: var(--text-color-positive);
    font-family: "Titillium Web", HelveticaNeue, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
    font-weight: 600;
    line-height: 1em;
    text-decoration: none;
    border: 0;
    text-align: center;
    cursor: pointer;
    overflow: hidden
}

._1N2Gm, ._3Y6Uy {
    display: block;
    float: left;
}

._1N2Gm {
    margin: 0 -.4em 0 0;
    padding: 0.6em .8em .5em;
    border-right: rgba(255, 255, 255, 0.1) 0.1em solid;
}

._1N2Gm>img {
    margin-bottom: 0rem !important;
}

._3Y6Uy {
    padding: .95em 1em .85em 1em;
    font-size: 1.15em;
    text-align: center;
}

._3nnX2 ._3Y6Uy {
    color: var(--text-color-positive);
}

._34hSf._3Y6Uy {
    color: var(--text-color-negative);
}

._3KoyM svg {
    width: 1.8em;
    height: 1.8em;
}

._2YBG4 {
    display: block
}

/**
 * Width of this class has been relaxed to fix #61
 * It differs from the original style
 */
._1nnOC {
    font-size: 10px;
    min-width: 150px
}

._1nnOC>span img {
    width: 19px;
    height: 19px;
    border: 0;
}
/**
 * Width of this class has been relaxed to fix #61
 * It differs from the original style
 */
._fufY9 {
    font-size: 15px;
    min-width: 220px
}

._fufY9>span img {
    width: 29px;
    height: 29px;
    border: 0
}
/**
 * Width of this class has been relaxed to fix #61
 * It differs from the original style
 */
._1t2ay {
    font-size: 20px;
    min-width: 280px
}

._1t2ay>span img {
    width: 38px;
    height: 38px;
    border: 0;
}

._2RDg8 {
    font-size: 25px;
    width: 340px
}

._2RDg8>span img {
    width: 47px;
    height: 47px;
    border: 0;
}

._3nnX2 {
    background-color: var(--background-color-positive);
    color: var(--text-color-positive);
}

._3nnX2:hover {
    background-color: var(--color-darker);
    color: var(--text-color-positive);
}

._3nnX2:active {
    background-color: var(--color-lighter);
    color: var(--color-darker)
}

._34hSf {
    color: var(--text-color-negative);
    background-color: var(--background-color-negative);
}

._34hSf:hover {
    color: var(--color-darker);
    background-color: var(--background-color-negative);
}

._34hSf:active {
    color: var(--color-lighter);
    background-color: var(--color-darker)
}

._2MOel {
    position: absolute;
    z-index: 1039;
    top: 100%;
    left: 0
}

._2MOel ._2ZNoV, ._2MOel ._2fv_g {
    list-style: none;
    background: white;
    border: solid 1px #ddd;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    overflow: visible;
    padding: 0;
    margin: 0
}

._2Xqdi, ._oYtNc {
    width: 230px;
}

.__96uF {
    width: 270px;
}

._3x_V1 {
    width: 330px;
}

._2MOel ._2fv_g {
    padding: 10px;
}

._2MOel._26Zsw {
    margin-top: 8px;
}

._2MOel._26Zsw:before {
    position: absolute;
    content: "";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ddd;
    display: inline-block;
    top: -6px;
    left: 9px;
}

._2MOel._26Zsw:after {
    position: absolute;
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    display: inline-block;
    top: -5px;
    left: 10px;
}

._2MOel._26Zsw._2NcMo:before {
    left: auto;
    right: 9px
}

._2MOel._26Zsw._2NcMo:after {
    left: auto;
    right: 10px
}

._2MOel._1xsHh ._2ZNoV, ._2MOel._1xsHh ._2fv_g {
    max-height: 180px;
    overflow: auto
}

._2MOel ._2ZNoV li {
    list-style: none;
    padding: 0 0;
    margin: 0;
    line-height: 18px
}

._2MOel ._2ZNoV li>a, ._2MOel ._2ZNoV label {
    display: block;
    font-family: "Titillium Web", HelveticaNeue, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
    font-weight: 600;
    font-size: .9em;
    color: #06C;
    text-decoration: underline;
    line-height: 18px;
    padding-top: 5px;
    white-space: nowrap;
    border-bottom: 1px solid #ddd
}

._2MOel ._2ZNoV li>a:hover, ._2MOel ._2ZNoV label:hover {
    color: var(--color-darker);
    cursor: pointer;
    background-color: #F0F0F0
}

._1l0mt {
    font-size: 100%;
    height: 10%;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #CCC;
    background-color: #FFF;
    padding: 15px !important;
    text-align: left;
    cursor: pointer
}

._1l0mt:hover {
    background-color: #F0F0F0
}

._1l0mt img {
    height: 25px;
    vertical-align: middle;
    cursor: pointer
}

.__SNdl>a img {
    margin-top: 10px;
}

._3PWn6>a img {
    margin-top: 20px;
}

._1A_0H {
    border-top: 5px solid #000
}

._5Fo33>a {
    padding: 5px 0 10px 10px
}

._2EZDG {
    color: #aaa;
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none;
}
